<template>
  <v-container>
    <tasks-templates-list
      :title="title"
      :headers="headers"
      :data="data"
      :response="response"
      :loading="loading"
      :create-task-target="'TasksNew'"
      @reload="loadTemplates"
      @new="newFn"
      @edit="editFn"
      @remove="removeFn"
    ></tasks-templates-list>
    <delete-dialog
      :dialog="deleteDialog"
      :item="templateName"
      @agree="removeHandler"
      v-show="false"
    />
  </v-container>
</template>

<script>
import api from "@/utils/api.js";
import TasksTemplatesList from "@/components/lists/TemplatesList.vue";
import snackbar from "@/utils/snackbar.js";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
export default {
  components: {
    TasksTemplatesList,
    DeleteDialog,
  },
  data: () => ({
    headers: [
      {
        text: "Title",
        align: "left",
        value: "name",
      },
      {
        text: "Boat",
        align: "left",
        value: "boat",
      },
      {
        text: "Type",
        align: "left",
        value: "type",
      },
      {
        text: "Visibility",
        align: "left",
        value: "crm_group",
      },
      { text: "", align: "right", value: "action", sortable: false },
    ],
    data: [],
    sort: "name",
    title: "Checklists",
    deleteDialog: false,
    templateName: null,
    template: {},
    boat: null,
    boatName: null,
    loading: true,
    response: null,
  }),
  created() {
    if (this.$route.query.page || this.$route.query.search) {
      this.loadTemplates(this.$route.query);
    } else {
      this.loadTemplates();
    }
  },
  methods: {
    loadTemplates(params) {
      if (!params) {
        params = {};
      }
      this.loading = true;
      this.data = [];
      api.getTemplateList(params).then((resp) => {
        this.response = resp.data;
        this.data = resp.data.results;
        this.loading = false;
      });
    },

    editFn(task) {
      this.$router.push({
        name: "TasksTemplatesEdit",
        params: { id: task.uuid },
      });
    },

    removeFn(template) {
      if (template.checklists.length === 0) {
        this.templateName = "Checklist 1";
      } else {
        this.templateName = template.checklists[0].name;
      }
      this.template = template;
      this.deleteDialog = true;
    },

    removeHandler(agreed) {
      if (agreed) {
        api.deleteTemplate(this.template.uuid).then((resp) => {
          snackbar.success("Successfully deleted");
          this.loadTemplates(this.$route.query);
        });
      }
      this.deleteDialog = false;
    },

    newFn(task) {
      this.$router.push({
        name: "TasksTemplatesNew",
      });
    },
  },
};
</script>
